import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import i18n from "./i18n";
import  VueFullscreen  from 'vue-fullscreen';
import VueLazyLoad from 'vue3-lazyload'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.use(VueFullscreen)
app.use(VueLazyLoad,{})
app.mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }